<template>
  <div id="app">
    <keep-alive>
      <router-view :key="$route.fullPath" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view :key="$route.fullPath" v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data(){
    return {
      isTest: true
    }
  },
  created() {
    window.isTest = this.isTest;
  },
  mounted() {

  },
  methods: {
    ...mapMutations(["refreshStatus", "refreshDeviceCloundInfo"]),
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: 12pt;
}

  .el-loading-spinner{
  font-size: 30px;

}
.el-loading-spinner .el-icon-loading {
  /*隐藏 之前  element-ui  默认的 loading 动画*/

  // display: none;
  color: #000000;

}

.el-loading-spinner .el-loading-text{
  /*为了使得文字在loading图下面*/
  // margin:150px 0px;
  font-size: 20px !important;
  font-weight: bolder !important;
  color: #000 !important;
}


</style>
